<template>
  <v-popover
    ref="popover"
    offset="0px"
    :placement="placement"
    :handleResize="true"
    :popoverClass="
      popUp == 'venteENeuro' || popUp == 'previsiteENeuro'
        ? 'popover-champs-project color-mouve'
        : 'popover-champs-project color-blue'
    "
  >
    <slot></slot>
    <template slot="popover">
      <div class="popover-champs-project-content">
        <div v-if="popUp == 'venteENeuro'">
          <div class="value-champs">
            <div class="nom-champs">
              {{ project.how_calculate_venteENeuro_text }}
            </div>
          </div>
          <hr class="line-pop-up" />
          <div class="value-champs">
            <div class="nom-champs">
              {{ project.how_calculate_venteENeuro_number }}
            </div>
          </div>
        </div>
        <div v-if="popUp == 'previsiteENeuro'">
          <div class="value-champs">
            <div class="nom-champs">
              {{ project.how_calculate_previsiteENeuro_text }}
            </div>
          </div>
          <hr class="line-pop-up" />
          <div class="value-champs">
            <div class="nom-champs">
              {{ project.how_calculate_previsiteENeuro_number }}
            </div>
          </div>
        </div>
        <div v-else></div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue';
import { VPopover } from 'v-tooltip';
import { mapActions, mapGetters } from 'vuex';
Vue.component('v-popover', VPopover);
export default {
  name: 'TooltipInput',
  components: {},
  props: {
    placement: {
      type: String,
      default: 'auto'
    },
    project: { required: true },
    popUp: { required: false }
  },
  data() {
    return {
      clickDelete: false
    };
  },
  mounted() {}
};
</script>

<style lang="scss">
.tooltip {
  &.popover-champs-project {
    opacity: 1;
    display: block !important;
    z-index: 0;
    max-height: 380px;
    width: 380px;
    border-radius: 0 8px 8px 0;
    box-shadow: 0 2px 6px 2px rgba(60, 64, 67, 0.15);
    max-height: inherit;
    overflow-y: auto;
    overflow-x: hidden;

    .tooltip-inner {
      max-height: 523px;
      overflow: auto;
      // overflow: auto;
      width: 100%;
      background: black;
      color: white;
      border-radius: 0px;
      text-align: left;
      max-width: 100%;
      // max-height: 100%;
      background: #fff;
      color: black;
      padding: 10px;
      box-shadow: 0 5px 30px rgba(black, 0.1);

      .popover-champs-project-content {
        cursor: pointer;
        .title-groupe-popup {
          text-align: center;
          font-size: 11px;
          font-weight: bold;
        }
        .line-pop-up {
          margin-top: 4px;
          margin-bottom: 6px;
        }
        .delete-projectth {
          margin-top: 10px;
          a {
            color: #ff5722;
            font-weight: bold;
          }
        }
        .message-delete {
          padding: 7px;
        }
        .form-actions {
          .btn-success {
            color: #fff;
            background-color: #8bc34a;
            border-color: #8bc34a;
            user-select: none;
            margin: 0;
            padding: 0 4px 0 4px;
            border-radius: 2px;
            font-size: 11px;
            font-weight: 600;
            margin-left: 5px;
            &:hover {
              color: #fff;
              background-color: #699732;
              border-color: #699732;
            }
            &:focus {
              box-shadow: none;
            }
          }
          .btn-outline-secondary {
            color: #6c757d;
            border-color: #6c757d;
            user-select: none;
            margin: 0;
            padding: 0 2px 0 2px;
            border-radius: 2px;
            font-size: 11px;
            font-weight: 600;
            &:hover {
              color: #ffffff;
              background-color: #858585;
              border-color: #858585;
            }
          }
        }
        .value-champs {
          display: flex;
          justify-content: space-between;
          margin-bottom: 6px;
          // margin-bottom: 3px;
          .nom-champs {
            font-size: 11px;
            font-weight: bold;
            max-width: 189%;
          }
          .border-vertical {
            border: 1px solid lightgrey;
            // margin-left: 12px;
          }
        }
        &.disabled {
          cursor: default;
          // padding: 10px;
          // overflow-y: auto;
          // height: 134px;
          // max-height: 193px;
        }
      }
    }

    .tooltip-arrow {
      display: none;
    }

    &[x-placement^='top'] {
      margin-bottom: 2px;
      &.color-blue {
        border-left: 6px solid #4285f4;
      }
      &.color-orange {
        border-left: 6px solid #fbbc04;
      }
      &.color-mouve {
        border-left: 6px solid #8d8cb7;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;
      &.color-blue {
        border-left: 6px solid #4285f4;
      }
      &.color-orange {
        border-left: 6px solid #fbbc04;
      }
      &.color-mouve {
        border-left: 6px solid #8d8cb7;
      }
    }

    &[x-placement^='right'] {
      &.color-blue {
        margin-left: 5px;
        margin-top: -18px;
        border-left: 6px solid #4285f4;
      }
      &.color-orange {
        margin-left: 5px;
        margin-top: -18px;
        border-left: 6px solid #fbbc04;
      }
      &.color-mouve {
        margin-left: 5px;
        margin-top: -18px;
        border-left: 6px solid #8d8cb7;
      }
      margin-left: 5px;
      margin-top: -18px;
      border-left: 6px solid #858585;
    }

    &[x-placement^='left'] {
      margin-right: 5px;
      &.color-blue {
        border-left: 6px solid #4285f4;
      }
      &.color-orange {
        border-left: 6px solid #fbbc04;
      }
      &.color-mouve {
        border-left: 6px solid #8d8cb7;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
</style>
